.List {
  margin: 1vh 5vw;
  background-color: #fff;
  max-height: 50vh;
  min-height: 50vh;
  /* overflow-y: scroll; */
  padding: 0;
}

.ItemList {
  /* margin: 5px; */
  border-radius: 10px;
  font-size: 1rem;
}

.Button {
  background-color: var(--background-linear) !important;
}

.ItemList:hover,
.ItemList:active {
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
  background: var(--background-linear) !important;
  cursor: pointer;
  color: #fff !important;
  font-weight: bold;
}

.LogoutButton {
  position: fixed;
  right: 2vh;
  top: 2vh;
}

.Card {
  margin: 1rem;
  padding: 1rem;
  border-radius: 20px;
  text-align: center;
}

@media (max-width: 991px) {
  .List {
    margin: 1rem;
    max-height: 70vh;
    max-height: 70vh;
  }
  .Card {
    margin: 0;
    padding: 0;
    border-radius: 20px;
    text-align: center;
  }
}
