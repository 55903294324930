.public-layout {
  &__header {
    padding: 0px;
    background: $color-background-container;
    min-height: 16vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    background: $background-linear;
    &__child {
      padding: 24px;
      min-height: 75vh;
      display: flex;
      height: auto;
      width: 100%;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__footer {
    background: $color-background-container;
    padding: 10px;
    text-align: center;
  }
}

@media (min-width: 200px) and (max-width: 969px) {
  .public-layout {
    &__header {
      min-height: 10vh;
    }
    &__content {
      background: $background-linear-mobile;
      &__child {
        min-height: 82vh;
      }
    }
  }
}
