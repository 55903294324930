:root {
  --primary: #17549a;
  --secondary: #221d1e;
  --tertiary: #ec2320;
  --auxiliar: #b2b2b2;
  --layout: #f5f5f5;
  --border-radius: "15px";
  --white: #fff;
  --shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  --background-linear: linear-gradient(
    150deg,
    var(--primary),
    var(--secondary)
  );
  --background-linear-mobile: linear-gradient(
    360deg,
    var(--primary),
    var(--secondary)
  );
}

.mb-24 {
  margin-bottom: 24px;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.pt-2 {
  padding-top: 2rem;
}
.align-center {
  align-self: center;
}

.dashboard {
  min-height: 80vh;
}

.subtitle_dashboard {
  color: var(--primary);
  font-weight: bold;
}

.title {
  font-size: 1.2rem;
  color: var(--primary);
  font-weight: bold;
  text-align: center;
  color: var(--layout);
}

.card {
  box-shadow: "0px 40px 60px #0000000d";
  border-radius: var(--border-radius);
  height: 100%;
}

.card-title {
  box-shadow: "0px 40px 60px #0000000d";
  border-radius: var(--border-radius);
  background-color: var(--secondary);
  height: 100%;
}
.card-title:hover {
  cursor: pointer;
}

.primary_button {
  width: 8rem;
  margin: 10px;
}

.icon {
  width: 32px;
  height: 32px;
  background-color: var(--primary);
  border-radius: 8px;
  border: solid 1px var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon svg {
  color: var(--layout);
}

.overflow_x {
  overflow-x: scroll;
}

.alert {
  position: fixed;
  top: 20px;
  right: 10px;
}

.logo-img {
  width: 8rem;
}

.logo-img-mobile {
  width: 3rem;
}

.value {
  display: flex;
  flex-direction: row;
}
