.collections-form {
  &__form-item {
    margin-bottom: 10px !important;
    &__date-picker {
      width: 100%;
    }
    &__button {
      margin-bottom: 10px !important;
      text-align: center;
    }
  }
}